import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { useMediaQuery } from 'react-responsive'

function Home() {
  const [id, setId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://vsense.cloud:8000/generate')
      .then(response => response.json())
      .then(data => {
        setId(data.id);
        console.log("Received QR: ", data.id);
        const ws = new WebSocket(`wss://vsense.cloud:8000/ws/${data.id}`);
        ws.onmessage = (event) => {
          const sensorData = JSON.parse(event.data);
          console.log("Received Data: ", data.id)
          if (sensorData) {
            ws.close();
            navigate(`/sensorDisplay/${data.id}`);
          }
        };
        return () => ws.close();
      });
  }, [navigate]);

  const handleSkip = () => {
      navigate(`/realtime`);
  };

  const url = id ? `https://vsense.cloud/sensorGet/${id}` : '';
  
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

  useEffect(() => {
    if (isTabletOrMobile) {
      navigate(`/realtime`);
    }
  }, [isTabletOrMobile, navigate]);

  return (
    <div style={styles.container}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '75vh'}}>
            <img src="vSense_Icon.png" alt="vSense Icon" style={{ maxWidth: '300px', height: 'auto'}} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute'}}>
            <div style={styles.rect}>
                <QRCode value={url} size={300} fgColor="#111c4e"/>
            </div>
            <button onClick={handleSkip} style={styles.button}>
                Skip
            </button>
        </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundImage: 'url(/vSense_BG.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
  button: {
    padding: '15px 50px',
    fontSize: '20px',
    marginTop: '50vh',
    position: 'absolute',
    fontFamily: 'Orbitron',
    borderRadius: '6px',
    backgroundColor: '#394150',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  },
  rect: {
    width: '350px',
    height: '350px',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
  },
};

export default Home;
