import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'

function Sensor() {
  const { id } = useParams();
  const [data, setData] = useState({ alpha: null, beta: null, gamma: null });
  const navigate = useNavigate();
  const [permissionGranted, setPermissionGranted] = useState(false);
  const timeoutRef = useRef(null);
  const pendingDataRef = useRef(null);

  useEffect(() => {
    if (permissionGranted) {
      const handleOrientation = (event) => {
        const newData = {
          alpha: event.alpha,
          beta: event.beta,
          gamma: event.gamma,
        };
        setData(newData);
        
        pendingDataRef.current = newData;

        if (!timeoutRef.current) {
          timeoutRef.current = setTimeout(() => {
            fetchData();
          }, 100);
        }
      };

      const fetchData = () => {
        if (pendingDataRef.current) {
          fetch(`https://vsense.cloud:8000/sensor/${id}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(pendingDataRef.current),
          })
            .catch(error => {
              console.error('Error sending data:', error);
            });
        }
        timeoutRef.current = null;
      };

      if (window.DeviceOrientationEvent) {
        window.addEventListener('deviceorientation', handleOrientation, true);
      } else {
        alert('Device orientation not supported.');
      }

      return () => {
        window.removeEventListener('deviceorientation', handleOrientation, true);
      };
    }
  }, [id, permissionGranted]);

  const requestPermission = async () => {
    if (typeof DeviceOrientationEvent.requestPermission === 'function') {
      try {
        const response = await DeviceOrientationEvent.requestPermission();
        if (response === 'granted') {
          setPermissionGranted(true);
        } else {
          alert('Permission denied');
        }
      } catch (error) {
        console.error('Error requesting device orientation permission:', error);
      }
    } else {
      // Handle non-iOS devices or older iOS versions
      setPermissionGranted(true);
    }
  };

  const handleHome = () => {
    navigate(`/`);
  };

  const handleAbout = () => {
    window.open('https://vresolv.io/', '_blank');
  };

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

  return (
    <div>
      {!isTabletOrMobile && <div style={styles.container}>
      <div style={styles.topBar}>
          <div style={styles.buttonArea}>
            <img src="/nav_button_outline.png" alt="Nav Button Outline" style={styles.buttonOutline} />
            <button onClick={handleHome} style={styles.button}>
              Home
            </button>
            <button onClick={handleAbout} style={styles.button}>
              About vResolv.io
            </button>
          </div>
          <div style={styles.content}>
              <img src="/vSense_Icon_other.png" alt="vSense Icon" style={styles.logo} />
          </div>
          <div style={styles.SensorDataTable}>
            <div style={styles.sensorHeadingRect}>
              <h3 style={styles.sensorHeading}>Your Phone Says</h3>
            </div>
            <div style={styles.sensorArea}>
              <div style={styles.sensorSubArea}>
                <p style={styles.sensorLabel}>Yaw (alpha)</p>
                <p style={styles.sensorLabel}>Pitch (beta)</p>
                <p style={styles.sensorLabel}>Roll (gamma)</p>
              </div>
              <div style={styles.sensorSubArea}>
                <p style={styles.sensorValue}>{data.alpha !== null ? data.alpha.toFixed(2) : 'N/A'}</p>
                <p style={styles.sensorValue}>{data.beta !== null ? data.beta.toFixed(2) : 'N/A'}</p>
                <p style={styles.sensorValue}>{data.gamma !== null ? data.gamma.toFixed(2) : 'N/A'}</p>
              </div>
            </div>
          </div>
        </div>
        <div style={styles.features}>
          <div style={styles.featureCard}>
            <img src="/vSense_1.png" alt="Industry-Specific Solutions" style={styles.featureImage} />
            <div style={styles.featureText}>
              <h3>Industry-Specific Solutions</h3>
              <p style={styles.paragraphText}>Tailored templates for industries including healthcare and smart cities.</p>
            </div>
          </div>
          <div style={styles.featureCard}>
            <img src="/vSense_2.png" alt="Seamless Integration" style={styles.featureImage} />
            <div style={styles.featureText}>
              <h3>Seamless Integration</h3>
              <p style={styles.paragraphText}>Simple and straightforward integration with a robust API.</p>
            </div>
          </div>
          <div style={styles.featureCard}>
            <img src="/vSense_3.png" alt="Intuitive User Interface" style={styles.featureImage} />
            <div style={styles.featureText}>
              <h3>Intuitive User Interface</h3>
              <p style={styles.paragraphText}>Simplifies device management for non-technical users.</p>
              </div>
            </div>
          <div style={styles.featureCard}>
            <img src="/vSense_4.png" alt="Multiple Protocol Support" style={styles.featureImage} />
            <div style={styles.featureText}>
              <h3>Multiple Protocol Support</h3>
              <p style={styles.paragraphText}>Extensive compatibility with BLE, MQTT, HTTP, LoRa etc. protocols.</p>
            </div>
          </div>
          <div style={styles.featureCard}>
            <img src="/vSense_5.png" alt="Advanced Analytics" style={styles.featureImage} />
              <div style={styles.featureText}>
                <h3>Advanced Analytics</h3>
                <p style={styles.paragraphText}>Featuring detailed analytics of deployed IoT solution.</p>
            </div>
          </div>
        </div>
      </div>}
      {isTabletOrMobile && <div style={styles.containerMobile}>
        <div style={styles.topBar}>
          <div style={styles.buttonArea}>
            <img src="/nav_button_outline.png" alt="Nav Button Outline" style={styles.buttonOutlineMobile} />
            <button onClick={handleHome} style={styles.buttonMobile}>
              Home
            </button>
            <button onClick={handleAbout} style={styles.buttonMobile}>
              About vResolv.io
            </button>
          </div>
          <div style={styles.content}>
              <img src="/vSense_Icon_other.png" alt="vSense Icon" style={styles.logo} />
          </div>
          <div style={styles.SensorDataTableMobile}>
            <div style={styles.sensorHeadingRectMobile}>
              <h3 style={styles.sensorHeadingMobile}>Your Phone Says</h3>
            </div>
            <div style={styles.sensorAreaMobile}>
              <div style={styles.sensorSubArea}>
                <p style={styles.sensorLabelMobile}>Yaw (alpha)</p>
                <p style={styles.sensorLabelMobile}>Pitch (beta)</p>
                <p style={styles.sensorLabelMobile}>Roll (gamma)</p>
              </div>
              <div style={styles.sensorSubArea}>
                <p style={styles.sensorValueMobile}>{data.alpha !== null ? data.alpha.toFixed(2) : 'N/A'}</p>
                <p style={styles.sensorValueMobile}>{data.beta !== null ? data.beta.toFixed(2) : 'N/A'}</p>
                <p style={styles.sensorValueMobile}>{data.gamma !== null ? data.gamma.toFixed(2) : 'N/A'}</p>
              </div>
            </div>
          </div>
          <button onClick={requestPermission} disabled={permissionGranted} style={styles.buttonPermission}>
            Grant Permission
          </button>
        </div>
        <div style={styles.featuresMobile}>
          <div style={styles.featureCardMobile}>
            <img src="/vSense_mobile_1.png" alt="Industry-Specific Solutions" style={styles.featureImage} />
            <div style={styles.featureTextMobile}>
              <h3>Industry-Specific Solutions</h3>
              <p style={styles.paragraphTextMobile}>Tailored templates for industries including healthcare and smart cities.</p>
            </div>
          </div>
          <div style={styles.featureCardMobile}>
            <img src="/vSense_mobile_2.png" alt="Seamless Integration" style={styles.featureImage} />
            <div style={styles.featureTextMobile}>
              <h3>Seamless Integration</h3>
              <p style={styles.paragraphTextMobile}>Simple and straightforward integration with a robust API.</p>
            </div>
          </div>
          <div style={styles.featureCardMobile}>
            <img src="/vSense_mobile_3.png" alt="Intuitive User Interface" style={styles.featureImage} />
            <div style={styles.featureTextMobile}>
              <h3>Intuitive User Interface</h3>
              <p style={styles.paragraphTextMobile}>Simplifies device management for non-technical users.</p>
              </div>
            </div>
          <div style={styles.featureCardMobile}>
            <img src="/vSense_mobile_4.png" alt="Multiple Protocol Support" style={styles.featureImage} />
            <div style={styles.featureTextMobile}>
              <h3>Multiple Protocol Support</h3>
              <p style={styles.paragraphTextMobile}>Extensive compatibility with BLE, MQTT, HTTP, LoRa etc. protocols.</p>
            </div>
          </div>
          <div style={styles.featureCardMobile}>
            <img src="/vSense_mobile_5.png" alt="Advanced Analytics" style={styles.featureImage} />
            <div style={styles.featureTextMobile}>
              <h3>Advanced Analytics</h3>
              <p style={styles.paragraphTextMobile}>Featuring detailed analytics of deployed IoT solution.</p>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: 'url(/vSense_BG_other.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    color: 'white',
  },
  containerMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: 'url(/vSense_BG_mobile.png)',
    backgroundSize: 'cover',
    backgroundColor: 'black',
    backgroundPosition: 'center',
    minHeight: '100vh',
    color: 'white',
  },
  topBar: {
    display: 'flex',
    width: '95%',
    backgroundColor: 'transparent',
    padding: '10px 0',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonArea: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonOutline: {
    width: '30%',
    position: 'absolute',
    zIndex: 1,
    pointerEvents: 'none',
  },
  buttonOutlineMobile: {
    width: '90%',
    position: 'absolute',
    zIndex: 1,
    pointerEvents: 'none',
  },
  button: {
    width: '15vw',
    padding: '10px',
    fontSize: '15px',
    fontFamily: 'Orbitron',
    borderRadius: '12px',
    backgroundColor: 'transparent',
    color: 'white',
    borderColor: 'transparent',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  buttonMobile: {
    width: '45vw',
    padding: '10px',
    fontSize: '15px',
    fontFamily: 'Orbitron',
    borderRadius: '12px',
    backgroundColor: 'transparent',
    color: 'white',
    borderColor: 'transparent',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  buttonPermission: {
    width: '45vw',
    height: '5vh',
    backgroundColor: '#467f45',
    color: 'white',
    borderRadius: '10px',
    borderColor: 'transparent',
    marginTop: '20px',
  },
  SensorDataTable: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '70%',
    marginTop: '5vh',
  },
  SensorDataTableMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90%',
    marginTop: '5vh',
  },
  sensorHeadingRect: {
    backgroundColor: '#111c4e',
    width: '30%',
    height: '5vh',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderTopLeftRadius: '2vw',
    borderTopRightRadius: '2vw',
    marginRight: 'auto',
  },
  sensorHeadingRectMobile: {
    backgroundColor: '#111c4e',
    width: '40%',
    height: '5vh',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderTopLeftRadius: '3vw',
    borderTopRightRadius: '3vw',
    marginRight: 'auto',
  },
  sensorHeading: {
    color: 'white',
    fontSize: '1em',
  },
  sensorHeadingMobile: {
    color: 'white',
    fontSize: '0.75em',
  },
  sensorArea: {
    width: '100%',
    backgroundColor: 'white',
    borderBottomLeftRadius: '2vw',
    borderBottomRightRadius: '2vw',
    display: 'flex',
    justifyContent: 'column',
    flexDirection: 'column',
  },
  sensorAreaMobile: {
    width: '100%',
    backgroundColor: 'white',
    borderBottomLeftRadius: '3vw',
    borderBottomRightRadius: '3vw',
    display: 'flex',
    justifyContent: 'column',
    flexDirection: 'column',
  },
  sensorSubArea: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  sensorLabel: {
    color: '#111c4e',
    textAlign: 'center',
    flex: 1,
    fontSize: '1em',
  },
  sensorLabelMobile: {
    color: '#111c4e',
    textAlign: 'center',
    flex: 1,
    fontSize: '0.5em',
  },
  sensorValue: {
    color: '#111c4e',
    textAlign: 'center',
    flex: 1,
    fontWeight: 'bold',
    fontSize: '1.5em',
  },
  sensorValueMobile: {
    color: '#111c4e',
    textAlign: 'center',
    flex: 1,
    fontWeight: 'bold',
    fontSize: '0.75em',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '40px',
  },
  logo: {
    maxWidth: '60%',
    height: 'auto',
  },
  features: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    width: '90vw',
    marginTop: '25px',
  },
  featuresMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '100px',
  },
  featureCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '18%',
    margin: '10px',
    textAlign: 'center',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  featureCardMobile: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    margin: '10px',
    textAlign: 'center',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  featureImage: {
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
  },
  featureText: {
    position: 'relative',
    bottom: '9.1vw',
    color: 'white',
    padding: '0.5vw',
    borderRadius: '10px',
    textAlign: 'left',
    marginLeft: '0.5vw',
    fontSize: '1.12vw',
  },
  featureTextMobile: {
    position: 'relative',
    bottom: '38vw',
    color: 'white',
    padding: '0.5vw',
    borderRadius: '10px',
    textAlign: 'left',
    marginRight: '10vw',
    marginLeft: '5vw',
    fontSize: '5vw',
  },
  paragraphText: {
    fontSize: '0.6vw',
  },
  paragraphTextMobile: {
    fontSize: '2vw',
  },
};

export default Sensor;
