import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import RealTimeData from './RealTimeData';
import SensorData from './SensorData';
import Sensor from './Sensor';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/realtime" element={<RealTimeData />} />
        <Route path="/sensorDisplay/:id" element={<SensorData />} />
        <Route path="/sensorGet/:id" element={<Sensor />} />
      </Routes>
    </Router>
  );
}

export default App;
